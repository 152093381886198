/****** COLORS *******/
$white: white;

$light-peach: #e4dcd3;
$light-blue-grey: #aacae6;
$light-brown: #e4dcd3;
$brown: #a36b4f;
$brown-grey: #999;
$very-light-pink: #f6f3f2;
$brownish-grey: #666;
$greyish-brown: #444;
$color-lightgrey: #ddd;
$light-brown: #e4dcd3;
$moderate-red: #ca525d;

// Bulma Colors variables
$black: black;
$black-bis: #1c1b1b;
$black-ter: #262626;
$grey-darker: #9f9f9f;
$grey-dark: gainsboro;
$grey: #e6e6e6;
$grey-light: #f2f2f2;
$grey-lighter: #f2f2f2;
$turquoise: #00aad2;
$red: #e63312;
$orange: #a36b4f;
$blue-darker: #001432; // Natblå (from NC design guide)
$blue-dark: #334a69; // Natblå 60%  (from NC design guide)
$blue: #019aca; // Blå (from NC design guide)
$blue-light: #32aed5; // Blå 60% (from NC design guide)

$white-ter: #e4e8e8;
$white-bis: hsl(0, 0%, 98%);

$yellow: hsl(48, 100%, 67%);
$yellow-dark: #d2b887;
$green: hsl(141, 71%, 48%);
$cyan: hsl(204, 86%, 53%);
$purple: hsl(271, 100%, 71%);

$primary: $blue;

$info: $cyan;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-darker;

// General colors
$foreground: $white;
$background: $white;

$border: $grey-lighter;
$border-hover: $grey-light;

// Text colors
$text: $blue-darker; // Text color
$text-invert: findColorInvert($text); // Text color inverted
$text-strong: $blue-darker;
$text-light: $grey-darker;

// Code colors
$code: $red;
$code-background: $background;

$pre: $text;
$pre-background: $background;

$logo-color: $blue;

$color-divider: $grey-lighter;
$mega-sidemenu-label-color: $blue-darker;
