$header-height-mobile: 6.5rem;
$header-height-desktop: 11.5rem;
$scrollbar-thumb-bg: #325680;

/****** PAGE TRANSITION *******/
$page-transition-bg: $blue-darker;
$page-transition-text: $white;

// MENU LIST
$menu-item-color: $blue-darker;
$menu-item-hover-color: $blue-dark;

// MEGA MENU
$mega-menu-padding-top: 9.5rem;
$mega-menu-border-top-space: 4.3rem;
$mega-menu-title-size-key: small;
$mega-menu-title-family-key: base;
$mega-menu-link-color: $blue-darker;
$mega-menu-link-color-hover: $blue;
$mega-sidemenu-link-color: $blue-darker;
$mega-sidemenu-link-color-hover: $blue;
$mega-sidemenu-link-icon-color: $blue-darker;

$sub-nav-header-bg: $blue-darker;

// HERO
$hero-title-default-size: largest;
$hero-title-desktop-size-key: larger;
$hero-title-fullhd-size-key: huge;
$hero-content-max-width: 89rem;
$hero-title-family-key: bold;
$hero-title-margin-bottom: 2rem;

/****** THEME *******/
// Default background row
$default-theme-bg: $white;
$default-theme-text: $text;

// Dark background row
$dark-theme-bg: $blue-darker;
$dark-theme-text: $white;

// Light background row
$light-theme-bg: $grey-light;
$light-theme-text: $text;
$rte-content-text-color-light: $text;

$grid-banner-row-has-bg: false;

$rte-default-theme-text: $blue-darker;
$rte-dark-theme-text: $white;
$rte-light-theme-text: $blue-darker;

/****** BUTTONS *******/
$button-secondary-theme: 'filled';
$button-height-normal: 4.5rem;
$button-padding-horizontal: 2.5rem;
$button-size-key: small;
$button-family-key: bold;
// $button-text-transform: uppercase;
$button-letter-space-key: x-large;
$button-filter-color: $grey-dark;
$button-filter-color-active: $blue;

// Primary button
$button-primary-color: $white;
$button-primary-bg: $blue-darker;
$button-primary-hover-bg: $blue-dark;
$button-primary-active-bg: $blue-dark;
$button-primary-text-hover-color: $white;
$button-primary-border-color: $blue-darker;
$button-primary-border-hover-color: $blue-dark;
// Secondary button
$button-secondary-color: $white;
$button-secondary-bg: $blue-darker;
$button-secondary-hover-bg: $blue-dark;
$button-secondary-active-bg: $blue-dark;
$button-secondary-text-hover-color: $white;
$button-secondary-border-color: $blue-darker;
$button-secondary-border-hover-color: $blue-dark;
// Filled button in dark row theme
$button-filled-dark-text-color: $blue-darker;
$button-filled-dark-bg: $white;
$button-filled-dark-bg-hover: $primary;
$button-filled-dark-bg-active: $primary;
$button-filled-dark-text-hover-color: $white;
$button-filled-dark-border-color: $blue-darker;
$button-filled-dark-border-hover-color: $blue;
// Filled button in light row theme
$button-filled-light-text-color: $white;
$button-filled-light-text-hover-color: $white;
$button-filled-light-bg: $blue-darker;
$button-filled-light-bg-hover: $blue-dark;
$button-filled-light-bg-active: $blue-dark;
$button-filled-light-border-color: $blue-darker;
$button-filled-light-border-hover-color: $blue-dark;
// button outline in dark row theme
$button-outline-dark-text-color: $blue-darker;
$button-outline-dark-bg: $white;
$button-outline-dark-bg-hover: $blue-dark;
$button-outline-dark-bg-active: $blue-dark;
$button-outline-dark-text-hover-color: $white;
$button-outline-dark-border-color: $white;
$button-outline-dark-border-hover-color: $blue-dark;
// button outline in default row theme
$button-outline-text-color: $blue-darker;
$button-outline-text-hover-color: $white;
$button-outline-bg: transparent;
$button-outline-bg-hover: $blue-dark;
$button-outline-bg-active: $blue-dark;
$button-outline-border-color: $blue-darker;
$button-outline-border-hover-color: $blue-dark;
// Outline button in light row theme
$button-outline-light-text-color: $blue-darker;
$button-outline-light-text-hover-color: $white;
$button-outline-light-bg: transparent;
$button-outline-light-bg-hover: $blue-dark;
$button-outline-light-bg-active: $blue-dark;
$button-outline-light-border-color: $blue-darker;
$button-outline-light-border-hover-color: $blue-dark;
// Button Hero
$button-hero-color: $button-primary-color;
$button-hero-bg: $button-primary-bg;
$button-hero-hover-bg: $button-primary-hover-bg;
$button-hero-active-bg: $button-primary-active-bg;
$button-hero-text-hover-color: $button-primary-text-hover-color;
$button-hero-border-color: $button-primary-border-color;
$button-hero-border-hover-color: $button-primary-border-hover-color;
// Is link
$button-link-text: $blue-dark;
$button-link-text-hover: lighten($blue-dark, 20%);

// HEADLINE SPOT
$headline-title-family-key: bold;
$headline-subtitle-desktop-font-key: medium;

// SIDEMENU
$side-menu-family-key: bold;
$side-menu-has-border-top: true;

// Mega sidemenu
$mega-sidemenu-label-size-key: largeMobile;
$mega-sidemenu-margin-top: 0;

$body-copy-bold-family-key: bold;
$body-copy-bold-color: $black-ter;

/****** MOBILE MENU *******/
$mobile-header-spacing-top: 3.2rem;
$mobile-header-spacing-bottom: 1rem;

$mobile-menu-link-color: $black-bis;
$mobile-menu-goto-color: $black;
$mobile-menu-goto-size-key: small;
$mobile-menu-goto-family-key: bold;
$mobile-menu-goto-padding-bottom: 1.6rem;

$mobile-menu-text-color: $black;

$mobile-menu-title-size-key: small;
$mobile-menu-title-family-key: bold;
$mobile-menu-title-line-height-key: large;
$mobile-menu-title-color: $black;
$mobile-menu-title-transform: uppercase;

$mobile-holder-horizontal-padding: $gutter;

$mobile-nav-item-padding-left: 0;
$mobile-nav-item-padding-right: 0;
$mobile-nav-item-padding-top: 1.7rem;
$mobile-nav-item-padding-bottom: 2.3rem;

$mobile-chevron-x-cord: 4px;
$mobile-chevron-y-cord: 4px;

$mobile-nav-back-bg: transparent;
$mobile-nav-back-border-top: transparent;
$mobile-nav-back-border-bottom: transparent;
$mobile-nav-back-size-key: small;
$mobile-nav-back-color: $primary;
$mobile-nav-back-bottom: 1.6rem;

$mobile-breadcrumb-margin-left: -1.2rem;
$mobile-breadcrumb-padding-top: 0.6rem;

$mobile-links-color: $grey-darker;
$mobile-links-horizontal-padding: 0;
$mobile-links-vertical-padding: 2rem;
$mobile-links-icon-padding: 0.3rem;

$mobile-search-color: $grey-darker;

/****** ICONS *******/
$icon-default-size: 3.2rem;
$icon-tag-car-padding-vertical: 0.2rem;
$icon-tag-car-padding-horizontal: 0.4rem;
$icon-tag-top: -0.5rem;
$icon-tag-right: -1.2rem;

/****** EXPANSION PANEL *******/
$expansion-panel-icon-color: $black;

/****** ACCORDION *******/
$accordion-padding-right: 0.5rem;

/****** INFO *******/
$info-default-color: $grey-darker;
$info-light-color: $grey-darker;
$info-label-size-key: small;

/****** PRODUCT DETAILS *******/
$has-product-details-border-top: true;
$product-details-title-color: $blue-darker;
$product-details-subtitle-color: $blue-dark;
$product-details-premium-bg-color: $grey-darker;
$product-details-tag-margin: 0.5rem;
$product-details-link-color-dark: $primary;
$product-details-separator-color: $border;
$product-details-specs-bg-color: #f9f9f9; // This is not used anywhere else
$product-details-premium-color: $grey-dark;
$product-details-block-header-color: $blue-darker;
$price-text-color: $blue-dark;

/****** FILTER PAGE *******/
$has-filter-page-border-top: true;
$filter-page-bg: #f4f4f4;

/****** SIDE MENU *******/
$side-menu-label-color: $blue;
$side-menu-link-active: $blue;
$side-menu-label-hover: $blue;

/****** HEADLINE *******/
$default-title-color: $blue-darker;
$default-subtitle-color: $blue-dark;

$light-title-color: $blue-darker;
$light-subtitle-color: $blue-dark;

$dark-title-color: $white;
$dark-subtitle-color: $grey-light;

/****** FILTER *******/
$product-sorting-color: $blue-darker;
$product-item-title-color: $blue-darker;
$product-item-details-color: $blue-dark;
$filter-tile-description-color: $blue-dark;

/****** FORMS *******/
$input-color: $blue-darker;
$input-color-light: $blue-darker;
$input-color-dark: $white;
$label-color: $blue-darker;

/****** LEGAL *******/
$legal-color: darken($grey-darker, 20%);

$model-list-item-price-color: $blue-dark;
$model-list-item-color-hover: $blue-darker;
$button-filter-color: $text;

/****** LEGAL *******/
$consent-text-color: $blue;
$consent-text-color-hover: $blue-light;

/****** LINK TAG  *******/
$link-default-color: $blue-dark;
$link-default-color-hover: $blue-darker;

/****** TIMELINE  *******/
$timeline-item-bg-default: $blue-darker;
$timeline-item-bg-dark: $white;
$timeline-thumb-bar-bg: $blue-darker;
$timeline-thumb-label-active-color: $blue-darker;
$timeline-item-color-default: $white;
$timeline-item-color-dark: $blue-darker;
$timeline-item-label-color-default: $white;
$timeline-item-label-color-dark: $blue-darker;
$timeline-thumb-label-active-color-default: $blue-darker;
$timeline-thumb-bar-bg-default: $grey-darker;
$timeline-thumb-bar-bg-dark: $white;

// TABLE
$table-cell-heading-color: $blue-darker;

$campaigns-spot-content-color: $text;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 0;
$breadcrumb-indent-mobile-small: 0;
$breadcrumb-indent-tablet: 15px;
$breadcrumb-indent-desktop: 0;
$breadcrumb-indent-widescreen: 27px;
$breadcrumb-indent-fullhd: 27px;
$breadcrumb-indent-fullwidth: 27px;
