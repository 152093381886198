$font-family-base: 'Inter', 'arial', 'sans-serif';
$font-family-base-medium: 'Inter', 'arial', 'sans-serif';
$font-family-base-bold: 'Inter', 'arial', 'sans-serif';
$font-family-secondary: 'Inter', 'arial', 'sans-serif';
$font-family-secondary-medium: 'Inter', 'arial', 'sans-serif';

/****** FONT SIZES CONFIG *******/
$font-size-mobile-menu: 1.1rem;
$font-size-icon-tag: 0.8rem;
$font-size-tiny: 1.1rem;
$font-size-x-small: 1.2rem;
$font-size-small: 1.4rem;
$font-size-sub: 1.5rem;
$font-size-base: 1.6rem;
$font-size-navigation: 1.6rem;
$font-size-search: 1.7rem;
$font-size-medium: 1.8rem;
$font-size-large: 2rem;
$font-size-large-mobile: 2.5rem;
$font-size-larger: 3rem;
$font-size-largest-mobile: 3.5rem;
$font-size-largest: 4.5rem;
$font-size-huge: 5.5rem;

/****** FONT WEIGHT CONFIG *******/
/* Remember to include the corresponding weights from the font family */
$font-weight-light: 300;
$font-weight-base: 300;
$font-weight-medium: 500;
$font-weight-secondary: 500;
$font-weight-bold: 500;

/****** LINE HEIGHT CONFIG *******/
$line-height-tiny: 0.9;
$line-height-normal: normal;
$line-height-small: 1.1;
$line-height-secondary: 1.2;
$line-height-base: 1.5;
$line-height-large: 2.1;

/****** LETTER SPACING CONFIG *******/
$letter-spacing-base: normal;
$letter-spacing-x-small: 0;
$letter-spacing-small: 0;
$letter-spacing-medium: 0;
$letter-spacing-large: 0;
$letter-spacing-x-large: 0;
$letter-spacing-huge: 0;

$secondary-heading-weight: 500;
$secondary-heading-size-key: larger;
$secondary-heading-family-key: bold;
$secondary-heading-line-key: secondary;
$secondary-heading-text-transform: none;
$secondary-heading-letter-space-key: small;
$secondary-heading-mobile-size-key: largeMobile;

$tertiary-heading-weight: 500;
$tertiary-heading-size-key: large;
$tertiary-heading-family-key: bold;
$tertiary-heading-line-key: secondary;
$tertiary-heading-letter-space-key: small;
$tertiary-heading-mobile-size: base;
$tertiary-heading-mobile-family: medium;
$tertiary-heading-mobile-line-height: base;
$tertiary-heading-mobile-weight: 500;

$body-copy-weight-key: 300;
$body-copy-family-key: base;

$body-copy-bold-size-key: base;
$body-copy-bold-family-key: secondaryMedium;
$body-copy-bold-weight: 500;

$body-copy-small-size-key: small;
$body-copy-small-family-key: secondary;
$body-copy-small-letter-space-key: medium;
$price-text-size: $font-size-larger;
$label-letter-weight-key: bold;
$headline-title-font-weight: 500;
$headline-subtitle-weight-key: 300;
$hero-subtitle-size-key: medium;
