$header-bg-color: $background;
$header-padding-horizontal: 4rem;

ncg-header {
    position: relative;
}

.header {
    position: relative;
    z-index: $index-header;
    background-color: $header-bg-color;

    &__backdrop {
        &::before {
            content: '';
            position: fixed;
            background-color: $black;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        &--is-open {
            &::before {
                opacity: 0.3;
            }
        }
    }

    &--animate {
        transition: transform $image-animation-duration-fast $smooth-in;
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
    }

    &__container {
        max-width: $fullwidth;
        margin: 0 auto;

        > .columns {
            padding-left: $gutter-small;
            padding-right: $gutter-small;
            height: $header-height-mobile;

            @include until($desktop) {
                padding-top: 1rem;
                padding-bottom: 0.8rem;
            }

            @include desktop {
                height: $header-height-desktop;
                padding-top: 2rem;
            }

            @include widescreen {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }
    }

    &__main-menu {
        @include desktop {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    &__logo {
        text-align: center;
        z-index: $index-header + 1;

        @include until($desktop) {
            order: 2;
        }

        .logo {
            img {
                max-width: 12rem;
                max-height: 6.5rem;

                @include desktop {
                    max-width: 18rem;
                }
            }
        }
    }

    &__meta-menu {
        @include until($desktop) {
            order: 1;
            align-items: flex-start;
            justify-content: flex-start;
            display: flex;

            a {
                color: $primary;
            }
        }
    }

    &__mobile-menu {
        @include until($desktop) {
            order: 3;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}

.header-language {
    @include mobile {
        margin-left: -0.4rem;
    }

    @include desktop {
        display: flex;
        justify-content: flex-end;
    }

    .navbar {
        .navbar-item {
            @include mobile {
                font-size: map-get($font-sizes, x-small);
                padding: 0.4rem;
            }
        }
    }
}
