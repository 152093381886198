$footer-background-color: $blue-darker;
$footer-divider: $white;
$footer-border: 1px solid rgba($footer-divider, 0.1);
$footer-navbar-item: $white;
$footer-navbar-item-hover: $blue;
$footer-navbar-item-opacity: 1;
$footer-icon-color: $white;

ncg-footer {
    position: relative;
    z-index: $index-body-overlay;

    .footer {
        background-color: $footer-background-color;

        &-top {
            padding-top: 4rem;

            @include until($desktop) {
                padding-top: 1rem;
            }

            &__columns {
                @include until($desktop) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }

            &__column {
                @include until($desktop) {
                    padding-bottom: 2rem;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: $footer-border;

                    .expansion-panel {
                        &__icon {
                            color: $white;
                        }
                    }
                }

                @include from($desktop) {
                    .expansion-panel {
                        &__content {
                            height: auto !important;
                        }
                    }
                }
            }

            &__menu-label {
                flex: 1 0 auto;
                font-family: map-get($font-families, bold);

                @include from($desktop) {
                    padding-bottom: 1rem;
                }

                a {
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }

            &__menu-list {
                a {
                    @include typeset(small);
                    padding: 0.6rem 0;
                    line-height: 1.2;
                    letter-spacing: normal;
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }
        }

        &-bottom {
            @include until($desktop) {
                flex-direction: column;
                padding-top: 0;

                > .container {
                    flex-direction: column;
                }
            }

            @include from($desktop) {
                padding-top: 3rem;
                border-top: $footer-border;
            }

            &__logo {
                display: block;
                min-width: 14rem;

                @include until($desktop) {
                    order: 1;
                    align-self: center;
                    padding-bottom: 2.5rem;
                }

                @include from($desktop) {
                    padding-right: 2rem;
                }
            }

            &__links {
                flex: 1 0 auto;

                @include until($desktop) {
                    order: 3;
                }
            }

            &__navbar {
                @include until($desktop) {
                    display: flex;
                    flex-flow: wrap row;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: center;

                    .navbar-item {
                        font-size: map-get($font-sizes, x-small);
                    }
                }
            }

            &__item {
                color: $footer-navbar-item;

                &:hover {
                    color: $footer-navbar-item-hover !important;
                }

                &--divider {
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        height: 60%;
                        width: 1px;
                        background-color: $footer-divider;
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &__social {
                @include until($desktop) {
                    order: 2;
                    padding-bottom: 2.5rem;
                }

                .footer-bottom {
                    &__item {
                        color: $footer-icon-color;

                        @include until($desktop) {
                            margin: 0 1rem;
                        }
                    }
                }
            }

            &__copyright {
                @include typeset(small);
                padding-left: 1rem;
                color: rgba($white, 0.5);

                @include until($desktop) {
                    padding-top: 2rem;
                    font-size: map-get($font-sizes, x-small);
                    text-align: center;
                }
            }
        }
    }
}
