@import '_variables/color-variables';

/****** COMMOM DEFAULT VARIABLES *******/
@import '../../../../libs/common-styles/src/lib/styles/styles-variables';

/****** VARIABLES *******/
@import '_variables/variables';
@import '_variables/typography';

/****** COMMON LIB *******/
@import '../../../../libs/common-styles/src/lib/styles/styles';

/****** COMPONENTS *******/
@import 'components/navbar';

/****** FEATURES *******/
@import 'features/footer';
@import 'features/header';
@import 'features/meta-menu';
@import 'features/main-menu';
