$menu-hover-cover-height: 2.7rem;
$menu-hover-cover-top-horizontal: -0.7rem;
$menu-hover-cover-bottom-horizontal: -4rem;

.main-menu {
    &.navbar {
        position: static;

        .main-menu {
            &__item {
                @include typeset(navigation, medium, base, 500);

                @include tablet {
                    font-size: map-get($font-sizes, small);
                }

                @include fullhd {
                    font-size: map-get($font-sizes, navigation);
                }

                &:hover {
                    z-index: 2;

                    &::before,
                    &::after,
                    span::before,
                    span::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 5rem;
                    }

                    &::before {
                        top: 50%;
                        left: $menu-hover-cover-top-horizontal;
                    }

                    &::after {
                        top: $menu-hover-cover-height;
                        left: $menu-hover-cover-bottom-horizontal;
                    }

                    span {
                        &::before {
                            top: 50%;
                            right: $menu-hover-cover-top-horizontal;
                        }

                        &::after {
                            top: $menu-hover-cover-height;
                            right: $menu-hover-cover-bottom-horizontal;
                        }
                    }
                }
            }
        }
    }
}
